import { css } from "@emotion/react";
import styled from "@emotion/styled";

const getMargin = ({
    doubleVerticalSpacing,
    halfGutter,
    noGutter,
    noVerticalSpacing,
    theme,
}) => {
    const gutter = halfGutter ? theme.layout.halfGutter : theme.layout.gutter;

    return css`
        margin-left: ${noGutter ? `0` : `calc(${gutter} / -2)`};
        margin-right: ${noGutter ? `0` : `calc(${gutter} / -2)`};
        margin-top: ${noVerticalSpacing
            ? `0`
            : doubleVerticalSpacing
              ? `-${theme.layout.doubleGutter}`
              : `-${gutter}`};
        margin-bottom: ${noVerticalSpacing
            ? `0`
            : doubleVerticalSpacing
              ? `${theme.layout.doubleGutter}`
              : `${gutter}`};
    `;
};

const noWrap = ({ theme, noWrap }) => {
    let styles = ``;

    if (noWrap === "xs") {
        styles += `
            @media (min-width: ${theme.breakpoints.mobilePortrait}) {
                flex-wrap: nowrap;
            }
        `;
    }

    if (noWrap === "sm") {
        styles += `
            @media (min-width: ${theme.breakpoints.tabletPortrait}) {
                flex-wrap: nowrap;
            }
        `;
    }

    if (noWrap === "md") {
        styles += `
            @media (min-width: ${theme.breakpoints.tabletLandscape}) {
                flex-wrap: nowrap;
            }
        `;
    }

    if (noWrap === "lg") {
        styles += `
            @media (min-width: ${theme.breakpoints.desktop}) {
                flex-wrap: nowrap;
            }
        `;
    }

    if (noWrap === "xl") {
        styles += `
            @media (min-width: ${theme.breakpoints.largeDesktop}) {
                flex-wrap: nowrap;
            }
        `;
    }

    return css`
        ${styles}
    `;
};

const Row = styled.div`
    ${getMargin};
    ${noWrap};
    flex: ${({ grow }) => (grow ? `1` : `0 1 100%`)};
    display: flex;
    flex-direction: ${({ reverse }) => (reverse ? `row-reverse` : `row`)};
    flex-wrap: wrap;
    min-width: 100%;
    justify-content: ${({ justifyContent }) =>
        justifyContent ? justifyContent : "flex-start"};
    align-items: ${({ alignItems }) => (alignItems ? alignItems : "stretch")};

    &:last-of-type {
        margin-bottom: 0;
    }

    .ReactModal__Content & {
        margin-top: 0;
    }
`;

export default Row;
